<template>
  <b-card :class="vertical ? '' : 'mb-1'">
    <b-card-title class="font-title mb-1">
      <feather-icon class="mr-1" icon="WindIcon" size="18" />
      PM2.5监测（24小时）
    </b-card-title>
    <div
      id="hours_48_params_pm25"
      class="w-100"
      style="height: calc(100% - 3rem)"
    ></div>
  </b-card>
</template>

<script>
import { Mix } from "@antv/g2plot";
import { onMounted, onUnmounted, reactive, ref } from "@vue/composition-api";
import { routerParams } from "@/libs/utils/routerParams";
import { getHours48Params } from "@/api/hjj2";
import { momentFmt } from "@/libs/utils/moment";

export default {
  name: "Hours48ParamsPm25",
  props: ["vertical"],
  setup(props) {
    const vertical = props.vertical;
    const interval = ref(null);
    const project_id = routerParams("project_id");
    const event = reactive({
      chart: null,
      data: [],
    });
    const chart_init_hours_48_params_pm25 = (data = []) => {
      if (event.chart) {
        event.chart.destroy();
        event.chart = null;
      }
      const chart = new Mix("hours_48_params_pm25", {
        legend: {
          position: "top",
          itemName: {
            style: {
              fill: "#808695",
            },
          },
        },
        plots: [
          {
            type: "line",
            options: {
              data: data,
              xField: "time",
              yField: "value",
              seriesField: "name",
              color: ({ name }) => {
                if (name === "室外") {
                  return "#1890ff";
                }
                return "#28c76f";
              },
              xAxis: {
                label: {
                  formatter: (text) => {
                    // console.log('text',text)
                    return text.split(" ")[1];
                    // return text.replaceAll(" ", "\n").replaceAll("00:00", "00").replaceAll(".000000", "");
                  },
                },
              },
              yAxis: {
                title: {
                  text: "PM2.5 (μg/m3)",
                  style: {
                    fill: "#808695",
                  },
                },
                // line: {
                //   style: {
                //     stroke: "#82868b",
                //     lineWidth: 1,
                //     opacity: 0.7,
                //   },
                // },
                // grid: {
                //   line: {
                //     style: {
                //       stroke: "#82868b",
                //       lineWidth: 1,
                //       lineDash: [2, 8],
                //       strokeOpacity: 0.7,
                //       shadowColor: "#82868b",
                //       cursor: "pointer",
                //     },
                //   },
                // },
              },
              isStack: false,
              smooth: true,
            },
          },
        ],
      });
      chart.render();
      event.chart = chart;
    };
    const fetchHours48Params = () => {
      getHours48Params({
        project_id: project_id,
        meter_code: ["06750343PM25", "00fd6cdfb6fb57e7realPM25"],
      }).then((res) => {
        let params = {};
        let key = "";
        res.data.map((item) => {
          let name = "室内";
          if (item.meter_code === "06750343PM25") {
            name = "室外";
          }
          let time = momentFmt(item.time, "YYYY-MM-DD HH:00");
          key = item.meter_code + "_" + time;
          params[key] = {
            name: name,
            time: time,
            value: item.value,
            meter_code: item.meter_code,
            unit: item.unit,
          };
        });
        params = Object.values(params);
        // console.log('humi_params', params)
        event.data = params;
        if (event.chart) {
          chart_init_hours_48_params_pm25(params);
        }
      });
    };

    onMounted(() => {
      fetchHours48Params();
      chart_init_hours_48_params_pm25();
      interval.value = setInterval(() => {
        fetchHours48Params();
      }, 1000 * 60 * 60);
    });

    onUnmounted(() => {
      clearInterval(interval.value);
    });
  },
};
</script>

<style scoped></style>
